self.addEventListener('push', function (event) {
	console.log('SW push activated');
});

self.addEventListener('notificationclick', function (event) {
	event.notification.close();
	const url = event.notification.data?.url;

	event.waitUntil(
		clients.matchAll({ includeUncontrolled: true, type: 'window' }).then(function (clients) {
			for (i = 0; i < clients.length; i++) {
				if (url) {
					clients[i].navigate(url);
					clients[i].focus();
					break;
				}
			}
		}),
	);
});
